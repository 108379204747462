<template>
    <form-modal
        ref="formModal"
        :title="i18nNewExport"
        size="xl"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nExportPresentationJob"
                    :operation="'created'"
                />
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated"
                >
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nDate"
                                    label-for="filter-started-at-date-time"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_period')">
                                    <date-range-picker
                                        :child-class="validationClass($v.modal.start_date)"
                                        :value.sync="dateRange"
                                        :max-date="new Date()"
                                        :max-range-in-days="maxRangeInDays"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nExportType"
                                    label-for="export-type"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <translated-multiselect
                                        id="export-type"
                                        v-model="modal.type"
                                        :options="exportTypes"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.type)"
                                        @blur="$v.modal.type.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nExportOrder"
                                    label-for="export-order"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_option')"
                                >
                                    <translated-multiselect
                                        id="export-order"
                                        v-model="modal.order"
                                        :options="exportOrders"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.order)"
                                        @blur="$v.modal.order.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nExportDescriptionSlide"
                                    label-for="export-description-slide"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_option')"
                                >
                                    <translated-multiselect
                                        id="export-description-slide"
                                        v-model="modal.description_slide"
                                        :options="booleanOptions"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.description_slide)"
                                        @blur="$v.modal.description_slide.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nExportDescriptionInPhotoSlide"
                                    label-for="export-description_in_photo-slide"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_option')"
                                >
                                    <translated-multiselect
                                        id="export-description_in_photo-slide"
                                        v-model="modal.description_in_photo_slide"
                                        :options="booleanOptions"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.description_in_photo_slide)"
                                        @blur="$v.modal.description_in_photo_slide.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nExportTitleInPhotoSlide"
                                    label-for="export-title_in_photo-slide"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_option')"
                                >
                                    <translated-multiselect
                                        id="export-title_in_photo-slide"
                                        v-model="modal.title_in_photo_slide"
                                        :options="booleanOptions"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.title_in_photo_slide)"
                                        @blur="$v.modal.title_in_photo_slide.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nExportProductCategorySlide"
                                    label-for="export-product-category-slide"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_option')"
                                >
                                    <translated-multiselect
                                        id="export-product-category-slide"
                                        v-model="modal.product_category_slide"
                                        :options="booleanOptions"
                                        :disabled="submitLoading"
                                        :select-class="validationClass($v.modal.product_category_slide)"
                                        @blur="$v.modal.product_category_slide.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nEmail"
                                    label-for="name-input"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model.trim="modal.email"
                                        class="input-pdv-blue"
                                        type="email"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <b-tab :title="i18nFilters">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nQuestionnaire"
                                    label-for="filter-name"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'export-questionnaire'"
                                        ref="questionnaireMultiselect"
                                        v-model="modal.questionnaires"
                                        :service="'questionnaires'"
                                        :searchable="true"
                                        :multiple="true"
                                        :default-options="[
                                            {
                                                value: null,
                                                text: 'Todos',
                                            },
                                        ]"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    label-for="modal-business-unit"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-business-unit'"
                                        ref="businessUnitMultiselect"
                                        v-model="modal.business_units"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nUser"
                                    label-for="export-user"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'export-user'"
                                        ref="exportUser"
                                        v-model="modal.users"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nChain"
                                    label-for="modal-point-of-sale-chain"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-point-of-sale-chain'"
                                        ref="chainMultiselect"
                                        v-model="modal.chains"
                                        :service="'pos-chains'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nFlag"
                                    label-for="modal-point-of-sale-flag"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-point-of-sale-flag'"
                                        ref="flagMultiselect"
                                        v-model="modal.flags"
                                        :service="'pos-flags'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nChannel"
                                    label-for="modal-channel"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-channel'"
                                        ref="channelMultiselect"
                                        v-model="modal.channels"
                                        :service="'pos-channels'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nRegional"
                                    label-for="modal-regional"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-regional'"
                                        ref="regionalMultiselect"
                                        v-model="modal.regionals"
                                        :service="'pos-regionals'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nState"
                                    label-for="modal-state"
                                    class="label-pdv"
                                >
                                    <multiSelect
                                        :id="'modal-state'"
                                        ref="stateMultiselect"
                                        v-model="modal.states"
                                        label="label"
                                        track-by="value"
                                        :options="states"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nPointOfSale"
                                    label-for="modal-point-of-sale"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-point-of-sale'"
                                        ref="pointOfSaleMultiselect"
                                        v-model="modal.point_of_sales"
                                        :service="'pos'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nCategory"
                                    label-for="export-category"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'export-category'"
                                        ref="exportcategory"
                                        v-model="modal.categories"
                                        :service="'pos-categories'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nClass"
                                    label-for="export-class"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'export-class'"
                                        ref="exportclass"
                                        v-model="modal.classes"
                                        :service="'pos-categories'"
                                        :searchable="true"
                                        :multiple="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                v-if="tabIndex !== 1"
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :title="getI18n('COMMON.next')"
                :busy="submitLoading"
                @onClick="tabIndex++"
            />
            <save-button
                v-else
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :title="i18nGenerateReport"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import translatedMultiselect from '@src/components/translatedMultiselect'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import multiSelect from '@src/components/multiSelect.vue'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import filterVuetable from '@src/mixins/filterVuetable'
import ExportPresentationJobService from '@src/services/ExportPresentationJobService'
import exportPresentationJobTypes from '@src/mixins/exportPresentationJobTypes'
import moment from 'moment';

const i18nKey = 'EXPORT_PRESENTATION_JOBS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        DateRangePicker,
        translatedMultiselect,
        multiSelectWithService,
        multiSelect,
        CancelButton,
        SaveButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        exportPresentationJobTypes,
        fieldsValidation,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            modal: {
                questionnaires: [],
                business_unit_id: [],
                point_of_sales: [],
                users: [],
                categories: [],
                classes: [],
                flags: [],
                chains: [],
                channels: [],
                regionals: [],
                states: [],
                business_units: [],
                start_date: moment().startOf('day').toDate(),
                end_date: moment().startOf('day').toDate(),
                type: {
                    value: 'link',
                    text: this.getI18n(i18nKey, 'TYPES.link'),
                },
                order: {
                    value: 'point_of_sale',
                    text: this.getI18n(i18nKey, 'TITLES.pos_alias'),
                },
                description_in_photo_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
                title_in_photo_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
                description_slide: {
                    value: false,
                    text: this.getI18n(i18nCommon, 'no'),
                },
                product_category_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
            },
            dateRange: {},
            maxRangeInDays: 15,
            submitLoading: false,
            tabIndex: 0,
            booleanOptions: [
                {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
                {
                    value: false,
                    text: this.getI18n(i18nCommon, 'no'),
                },
            ],
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
            states: [
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goías' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraíma' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' },
            ],
        }
    },
    validations: {
        modal: {
            start_date: {
                required,
                beforeEndDate(value, model) {
                    return value <= model.end_date
                },
                maxRangePeriod(value, model) {
                    return ((model.end_date - value) / 86400000) <= this.maxRangeInDays;
                },
            },
            end_date: {
                required,
                afterStartDate(value, model) {
                    return value >= model.start_date
                },
            },
            type: {
                required,
            },
            order: {
                required,
            },
            description_slide: {
                required,
            },
            description_in_photo_slide: {
                required,
            },
            title_in_photo_slide: {
                required,
            },
            product_category_slide: {
                required,
            },
        },
    },
    computed: {
        i18nDate() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nExportType() {
            return this.getI18n(i18nKey, 'TITLES.export_type')
        },
        i18nExportOrder() {
            return this.getI18n(i18nKey, 'TITLES.export_order')
        },
        i18nExportDescriptionSlide() {
            return this.getI18n(i18nKey, 'TITLES.export_description_slide')
        },
        i18nExportDescriptionInPhotoSlide() {
            return this.getI18n(i18nKey, 'TITLES.description_in_photo_slide')
        },
        i18nExportTitleInPhotoSlide() {
            return this.getI18n(i18nKey, 'TITLES.title_in_photo_slide')
        },
        i18nExportProductCategorySlide() {
            return this.getI18n(i18nKey, 'TITLES.export_product_category_slide')
        },
        i18nEmail() {
            return this.getI18n(i18nKey, 'TITLES.email')
        },
        i18nQuestionnaire() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nKey, 'TITLES.business_unit')
        },
        i18nUser() {
            return this.getI18n(i18nKey, 'TITLES.user')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nFlag() {
            return this.getI18n('POS', 'TITLES.flag')
        },
        i18nChannel() {
            return this.getI18n(i18nKey, 'TITLES.channel')
        },
        i18nRegional() {
            return this.getI18n(i18nKey, 'TITLES.regional')
        },
        i18nState() {
            return this.getI18n(i18nKey, 'TITLES.state')
        },
        i18nPointOfSale() {
            return this.getI18n(i18nKey, 'TITLES.point_of_sale')
        },
        i18nCategory() {
            return this.getI18n('POS', 'TITLES.category')
        },
        i18nProduct() {
            return this.getI18n(i18nKey, 'TITLES.product')
        },
        i18nClass() {
            return this.getI18n(i18nKey, 'TITLES.class')
        },
        i18nNewExport() {
            return this.getI18n(i18nKey, 'BUTTONS.new_export')
        },
        i18nGenerateReport() {
            return this.getI18n(i18nKey, 'BUTTONS.generate_report')
        },
        i18nExportPresentationJob() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.export_presentation_job',
                modifier: 2,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nFilters() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'filter',
                modifier: 2,
            })
        },
    },
    watch: {
        dateRange: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.modal.start_date = newValue.start
                    this.modal.end_date = newValue.end

                    this.$v.modal.start_date.$touch()
                    this.$v.modal.end_date.$touch()
                }
                else {
                    this.modal.start_date = null
                    this.modal.end_date = null
                }
            },
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (oldTabIndex === 0) {
                this.$v.modal.$touch()
                if (this.$v.modal.$invalid) {
                    event.preventDefault();
                }
            }
        },
        changeType(option) {
            this.$v.modal.type.$model = option.value
            this.$v.modal.type.$touch()
        },
        createResumeParameters() {
            return this.formatParameters({
                business_unit_id: this.modal.business_units.map((businessUnit) => businessUnit.id),
                role_id: this.modal.roles.map((role) => role.id),
                user_id: this.modal.users.map((user) => user.id),
                start: this.modal.start_date,
                end: this.modal.end_date,
                export_type: this.modal.type,
                language: 'pt_BR',
            })
        },
        showModal() {
            this.submitLoading = false
            this.tabIndex = 0
            this.cleanModal()
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.dateRange = {
                start: moment().startOf('day').toDate(),
                end: moment().startOf('day').toDate(),
            }

            this.modal = Object.assign(this.modal, {
                questionnaires: [],
                business_unit_id: [],
                point_of_sales: [],
                users: [],
                categories: [],
                classes: [],
                flags: [],
                chains: [],
                channels: [],
                regionals: [],
                states: [],
                business_units: [],
                start_date: moment().startOf('day').toDate(),
                end_date: moment().startOf('day').toDate(),
                type: {
                    value: 'link',
                    text: this.getI18n(i18nKey, 'TYPES.link'),
                },
                order: {
                    value: 'point_of_sale',
                    text: this.getI18n(i18nKey, 'TITLES.pos_alias'),
                },
                description_in_photo_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
                title_in_photo_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
                description_slide: {
                    value: false,
                    text: this.getI18n(i18nCommon, 'no'),
                },
                product_category_slide: {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes'),
                },
            })
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleCancel() {
            this.$refs.confirmationFormModal.showModal(true, this.handleClose)
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modal.$touch()
            if (!this.$v.modal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const parameters = this.formatParameters({
                questionnaire_id: this.modal.questionnaires.map((questionnaire) => questionnaire.id),
                point_of_sale_id: this.modal.point_of_sales.map((pointOfSale) => pointOfSale.id),
                business_unit_id: this.modal.business_units.map((businessUnit) => businessUnit.id),
                user_id: this.modal.users.map((users) => users.id),
                product_category_id: this.modal.categories.map((category) => category.id),
                class_id: this.modal.classes.map((classes) => classes.id),
                flag_id: this.modal.flags.map((flag) => flag.id),
                chain_id: this.modal.chains.map((chain) => chain.id),
                channel_id: this.modal.channels.map((channel) => channel.id),
                regional_id: this.modal.regionals.map((regional) => regional.id),
                states: this.modal.states.map((state) => state.value),
                filtered_start: moment(this.modal.start_date).format('YYYY-MM-DD'),
                filtered_end: moment(this.modal.end_date).format('YYYY-MM-DD'),
                type: this.modal.type.value,
                maximum_photos_per_slide: 1,
                order: this.modal.order.value,
                description_in_photo_slide: this.modal.description_in_photo_slide.value,
                title_in_photo_slide: this.modal.title_in_photo_slide.value,
                description_slide: this.modal.description_slide.value,
                product_category_slide: this.modal.product_category_slide.value,
                language: 'pt_BR',
            })

            const response = await ExportPresentationJobService.create(parameters)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nExportPresentationJob, 'created')
            }

            if (statusCode === '417') {
                this.warningFeedback(this.i18nExportPresentationJob, response.data.message)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
    },
}
</script>
